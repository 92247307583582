const colors = {
    yellow: '#E0BF00',
    gray_00: '#E2E2E2',
    gray_01: '#525252',
    purple_00: '#201D1F',
    orange_00: '#fadfbd',
    orange_01: '#b69974',
    black: '#000',
}

export {
    colors
}