import { useRef } from 'react';
import './App.css';
import { 
  AppBar,
  Toolbar,
  Typography, 
  Stack, 
  Grid, 
  Box, 
  Container 
} from '@mui/material';
import { colors } from './constants';
import { MouseImage, LumiaSystemsLogoInverted, LumiaSystemsLogoTextOnlyInverted } from './images';
import { useOnScreen } from './common/utils';

const Title = () => (
  <img src={LumiaSystemsLogoTextOnlyInverted} alt='Lumia Systems' style={{ height: 22 }} />
)

const Logo = () => <Box sx={{
  position: 'relative',
  display: 'flex',
  alignItems: { xs: 'center'},
  height: { xs: 450, sm: 500, md: 650 },
}}>
  <img src={LumiaSystemsLogoInverted} alt='Lumia Systems Logo' style={{ transform: 'scale(0.3)' }} />
</Box>

const ListTile = ({ listTitle, listContent }) => <Box sx={{ 
    m: 2, 
    p: 2, 
    height: { md: '85%'}, 
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'start',
    color: colors.purple_00,
  }}>
    <Typography variant='h6' gutterBottom sx={{ fontFamily: 'Saira', textTransform: 'uppercase', fontWeight: 800 }}>{listTitle}</Typography>
    <Typography variant='body1' sx={{ fontFamily: 'Saira', textAlign: 'left', fontWeight: 'bold' }}>
      <ul>
        {
          listContent.map((listItem) => <><li>{listItem}</li><br/></>)
        }
      </ul>
    </Typography>
  </Box>

const App = () => {
  const ref = useRef(null);
  const isSplashVisible = useOnScreen(ref);

  return (
    <div className='App'>
      <header className='App-header'>
        <Container maxWidth='lg'>
          {
            !isSplashVisible && (
              <AppBar position="fixed" sx={{ height: 18 }}>
                <Toolbar sx={{ justifyContent: { xs: 'space-between', md: 'space-around'} }} >
                  <Title/>
                  <Typography sx={{ fontFamily: 'Saira'}}>lumiasystems@protonmail.com</Typography>
                </Toolbar>
              </AppBar>
          )}
          <Grid container>
            <Grid item xs={12} md={6} sx={{
              display: 'flex',
              justifyContent: { xs: 'center'},
              alignItems: { xs: 'center'},
              height: { xs: 450, md: '90vh'},
              minHeight: { xs: 450, md: 600 }
            }}>
              <Box 
                ref={ref}
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center'},
                  alignItems: { xs: 'center'},
                }}
              >
                <Logo/>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{
              display: 'flex',
              justifyContent: { xs: 'center'},
              alignItems: { xs: 'center'},
              height: { md: '85vh'},
              minHeight: { xs: 100, md: 500 }
            }}>
              <Box sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: { xs: 'center'},
                alignItems: { xs: 'flex-start', md: 'center'},
              }}>
                <Box sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                }}>
                  <Typography variant='h5' gutterBottom sx={{ fontWeight: 800, fontFamily: 'Saira' }}>An IT company that works differently</Typography>
                  <Typography variant='subtitle1' sx={{ fontFamily: 'Saira'}}>No overpriced services. No awkwardness. No hassle.</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <div className='transition-bg'>
          <Container maxWidth='lg'>
            <Grid container>
              <Grid item xs={12} sx={{ 
                display: 'flex',
                justifyContent: { xs: 'center'},
                alignItems: { xs: 'center'},
                minHeight: { xs: 700, md: '70vh' },
                paddingTop: { xs: 20, md: 0 }
              }}>
                <Box sx={{ minHeight: '45vh', p: { xs: 3, md: 5 }, marginBottom: { xs: 0, md: -20 } }}>
                  <Typography variant='h4' gutterBottom sx={{ fontFamily: 'Saira', color: colors.purple_00, fontWeight: 800 }}>
                    Our Services
                  </Typography>
                  <Typography variant='subtitle1' gutterBottom sx={{ fontFamily: 'Saira', color: colors.purple_00 }}>
                    Don’t see it on our list? Contact us directly for a quote!
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <ListTile 
                        listContent={[
                          'Software and hardware upgrades',
                          'Network speedup', 
                          'New computer setup',
                          'Printer installations', 
                          'Virus scans/cleanup, system and security updates, software installations'
                        ]}
                        listTitle='Basic IT Services'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ListTile 
                        listContent={[
                          'Guest WiFi setup',
                          'Security camera installations',
                          'Company cloud data backups', 
                          'Website/online store setup and maintenance'
                        ]}
                        listTitle='Business Infrastructure Setup'
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <ListTile 
                        listContent={[
                          'Server upgrades and custom solutions',
                          'Infrastructure virtualization',
                          'On-premise cloud migrations', 
                          'Infrastructure audits',
                          'Start-to-finish upgrades and servicing'
                        ]}
                        listTitle='Advanced Integrations'
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div className='transition-bg-inverse'>
          <Container maxWidth='lg'>
            <Grid container>
              <Grid item xs={12} sx={{
                display: 'flex',
                justifyContent: { xs: 'center'},
                alignItems: { xs: 'center'},
                height: '90vh',
                minHeight: 500,
              }}>
                <Grid container sx={{
                  display: 'flex',
                  justifyContent: { xs: 'center'},
                  alignItems: { xs: 'center'},
                }}>
                  <Grid item xs={12}>
                    <Typography variant='h4' gutterBottom sx={{ fontFamily: 'Saira'}}>Contact Us</Typography>
                  </Grid>
                  <Grid item xs={0} md={4}>
                    <Box sx={{ 
                      height: 300, 
                      width: 300, 
                      borderRadius: '50%', 
                      backgroundImage: `url(${MouseImage})`,
                      backgroundSize: 'cover',
                      backgroundPosition: '-100px 0',
                      display: { xs: 'none', md: 'inherit'}
                    }}/>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Stack spacing={2}>
                      <Typography variant='h6' sx={{ fontFamily: 'Saira'}}>Lumia Systems IT Services</Typography>
                      <a href="mailto:lumiasystems@protonmail.com"><Typography variant='h6' sx={{ fontFamily: 'Saira'}}>lumiasystems@protonmail.com</Typography></a>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </header>
    </div>
  );
}

export default App;
